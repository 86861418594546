import { template as template_8a38a9b84c3c41fea186dcc5cba7be9f } from "@ember/template-compiler";
const FKLabel = template_8a38a9b84c3c41fea186dcc5cba7be9f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
