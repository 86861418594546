import { template as template_932a5805b53e4a3c982b754ac011c102 } from "@ember/template-compiler";
const SidebarSectionMessage = template_932a5805b53e4a3c982b754ac011c102(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
